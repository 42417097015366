<template>
  <v-app id="login">
    <v-main class="login-panel">
      <v-container class="fill-height" fluid>
        <v-dialog v-model="showDialog" max-width="400px" persistent>
          <v-card flat class="Card-style pa-2">
            <v-container fluid>
              <v-layout justify-center>
                <v-flex xs12 sm12>
                  <v-flex xs12>
                    <!-- <label class="title text-xs-center"
                      >Email Notification</label
                    > -->
                    <p>
                      Verification email has been sent, please follow activation
                      link sent in email
                    </p>
                    <p>
                      <b>{{ emailIDSent }}</b>
                    </p>
                  </v-flex>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="emailVerification">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-dialog>
        <v-row align="center" justify="center">
          <v-card class="form-card">
            <loading :active="isLoading" :loader="loader" />
            <v-card-title>
              <v-card-text style="text-align: center; color: dodgerblue">
                <span class="header">Security Questions</span>
              </v-card-text>
            </v-card-title>
            <v-card-text class="securityForm">
              <span
                >These Answers will be saved and you would need to answer them
                correctly each time when Logging into account.</span
              >
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-col cols="12">
                  <v-select
                    :items="securityList"
                    v-model.trim="firstQuestion.quationsId"
                    item-text="description"
                    item-value="questionId"
                  >
                  </v-select>
                  <v-text-field
                    placeholder="Answer 1"
                    v-model.trim="firstQuestion.answer"
                    :rules="firstQusRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="securityList"
                    label="Select Question"
                    v-model.trim="secondQuestion.quationsId"
                    :rules="selectedSecondQusRules"
                    item-text="description"
                    item-value="questionId"
                  ></v-select>
                  <v-text-field
                    placeholder="Answer 2"
                    v-model.trim="secondQuestion.answer"
                    :rules="secondQusRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="securityList"
                    label="Select Question"
                    v-model.trim="thirdQuestion.quationsId"
                    :rules="selectedThirdQusRules"
                    item-text="description"
                    item-value="questionId"
                  ></v-select>
                  <v-text-field
                    placeholder="Answer 3"
                    v-model.trim="thirdQuestion.answer"
                    :rules="thirdQusRules"
                  ></v-text-field>
                </v-col>
                <v-layout>
                  <v-flex sm12>
                    <v-btn
                      color="primary"
                      block
                      @click="saveQuestions"
                      :disabled="!isFormValid"
                      >Save</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar
      timeout="3000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>
<script>
import authAPI from "@/services/authAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      userDetailData: {},
      isLoading: false,
      //fullPage: false,
      loader: "bars",
      emailIDSent: "",
      valid: false,
      showDialog: false,
      snackbar: {
        show: false,
        text: "",
        color: "",
      },
      e1: true,
      loading: false,
      securityList: [],
      firstQuestion: {},
      secondQuestion: {},
      thirdQuestion: {},
      isFormValid: true,
      signupData: {
        partyId: null,
      },
      firstQusRules: [(v) => !!v || "First question is required"],
      secondQusRules: [(v) => !!v || "Second question is required"],
      thirdQusRules: [(v) => !!v || "Third question is required"],
      selectedSecondQusRules: [
        (v) => !!v || "Select second question is required",
      ],
      selectedThirdQusRules: [
        (v) => !!v || "Select third question is required",
      ],
    };
  },
  components: {
    Loading,
  },
  mounted() {
    var signupData = {};
    signupData = JSON.parse(localStorage.getItem("signupData"));
    if (signupData != null) {
      this.signupData = signupData;
    }
    this.getSecurityList();
  },
  methods: {
    clear() {
      this.$refs.securityQuestionForm.reset();
    },
    async getSecurityList() {
      try {
        let response = await authAPI.getSecurityList();
        this.securityList = response.securityQuestions;
        this.firstQuestion.quationsId =
          this.securityList.length > 0 ? this.securityList[0].questionId : null;
      } catch (error) {}
    },
    async saveQuestions() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          //this.signupData = this.$store.state.signUpData;
          // this.signupData = JSON.parse(localStorage.getItem("storeSignupData"));
          // console.log("signdata",this.signupData)
          // let response = await authAPI.createAccount(this.signupData);
          // if (this.signupData == null && this.signupData == undefined) {
          //   this.signupData = JSON.parse(localStorage.getItem("userDetail"));
          //   console.log("this.signupData",this.signupData)
          // } else {
          //   this.signupData = JSON.parse(localStorage.getItem("signupData"));
          // }
          if (this.signupData.partyId != null) {
            this.signupData.partyId = this.signupData.partyId;
          } else {
            this.signupData.partyId = this.$route.params.partyId;
          }
          let securityDetail = {
            partyId: this.signupData.partyId,
            questionIdList: [
              this.firstQuestion,
              this.secondQuestion,
              this.thirdQuestion,
            ],
          };
          // console.log("securityDetail=============>C", securityDetail);
          // console.log("securityDetail====", securityDetail);
          await authAPI.saveQuestions(securityDetail);
          this.showDialog = true;
          this.isLoading = false;
        } catch (err) {
          console.log("err.data.messageDetail", err);
          this.snackbar = {
            show: true,
            text: err.data.messageDetail,
            color: "error",
          };
          this.isLoading = false;
        }
      }
    },
    async emailVerification() {
      this.isLoading = true;
      // if (this.signupData.agentDetail.data.email || this.$route.params.email) {
      //   this.emailIDSent = this.signupData.agentDetail.data.email;
      // } else {

      // }
      if (this.$route.params.email) {
        this.emailIDSent = this.$route.params.email;
      }
      try {
        await authAPI.emailVerification({
          emailAddress: this.emailIDSent,
          partyId: this.signupData.partyId,
        });
        this.showDialog = false;
        window.location.href = "/";
        localStorage.clear();
        //this.$store.dispatch("STORE_SIGNUP_DATA", {});
        this.isLoading = false;
      } catch (err) {
        this.snackbar = {
          show: true,
          text: err.data.messageDetail,
          color: "error",
        };
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.login-panel {
  background-image: url(../../assets/images/didpanel.jpg);
  background-attachment: fixed;
  background-size: cover;
}
.login-panel .form-card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 5px 20px;
  width: 500px;
  /* margin-top: 10%; */
}
.header {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
</style>
